import React from 'react';
// import logo from './logo.svg';
import './App.css';

function App() {
  RedirectAddress();

  return (
    <div>
    </div>
  );
}

function RedirectAddress()
{
  type ReAdd = 
  {
    key : string;
    value : string;
  }
  let adrList : ReAdd[] = [];

  //STANDARD
  adrList.push({key: "cv-web", value: "https://gknzby.com/"});
  adrList.push({key: "cv-github", value: "https://github.com/gknzby"});
  adrList.push({key: "cv-linkedin", value: "https://www.linkedin.com/in/gknzby/"});
  adrList.push({key: "cvorlinkedin", value: "https://www.linkedin.com/in/gknzby/"});  
  adrList.push({key: "portfolio", value: "https://github.com/gknzby"});

  //MINIGAMES
  adrList.push({key: "minigames-web", value: "https://github.com/gknzby/MiniGames"});
  adrList.push({key: "minigames-github", value: "https://github.com/gknzby/MiniGames"});
  adrList.push({key: "minigames-youtube", value: "https://youtube.com/shorts/mYW8Kr46BgA?feature=share"});

  //MYDESIGNS
  adrList.push({key: "mydesigns-web", value: "https://gknzby.myportfolio.com/"});

  //CUBECONQUER
  adrList.push({key: "cubeconquer-web", value: "https://github.com/gknzby/CubeConquer"});
  adrList.push({key: "cubeconquer-github", value: "https://github.com/gknzby/CubeConquer"});
  adrList.push({key: "cubeconquer-youtube", value: "https://youtube.com/shorts/xZkWkbI2WXs?feature=share"});

  //TACTICALBOUNCE
  adrList.push({key: "tacticalbounce-web", value: "https://github.com/gknzby/TacticalBounce"});
  adrList.push({key: "tacticalbounce-github", value: "https://github.com/gknzby/TacticalBounce"});
  adrList.push({key: "tacticalbounce-youtube", value: "https://youtube.com/shorts/0Ggdxt1NGcs?feature=share"});

  //RUNNERBOI
  adrList.push({key: "runnerboi-web", value: "https://github.com/gknzby/RunnerBoi"});
  adrList.push({key: "runnerboi-github", value: "https://github.com/gknzby/RunnerBoi"});
  adrList.push({key: "runnerboi-youtube", value: "https://youtube.com/shorts/PeI1PrF2F_w?feature=share"});



  let currentURL : string = window.location.href;
  let urlArray : string[] = currentURL.split('/');
  let last : number = urlArray.length - 1;

  console.log("--------TEST---------");
  console.log(urlArray);
  console.log(adrList);
  if(urlArray[last - 1].startsWith("redirect"))
  {
    adrList.forEach(rad => {
      if(rad.key.startsWith(urlArray[last]))
      {
        window.location.replace(rad.value);
      }
    });
  }

}



export default App;

/*
    render() {
        return (
            <div id="bgImg" className="App">
                <Header />
                <div className="Main">
                    <Menu />
                </div>
            </div>
        );
    }
*/